.react-datepicker-wrapper {
	margin: 3px !important;
	width: 49% !important;
}

.react-datepicker__input-container {
	height: 100%;
}

.react-datepicker-wrapper input {
	width: 100% !important;
	height: 100% !important;
	padding: 10px;
}
